import { bindActionCreators } from 'redux'
import { branch, renderComponent } from 'recompose'
import LoadingBar from '../views/Shared/LoadingBar'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from 'date-fns/locale/fr'
import * as Sentry from '@sentry/browser'
import dateParse from 'date-fns/parseISO'
import differenceInDays from 'date-fns/differenceInDays'
import QueryString from 'querystring'
import {
  compose,
  filter,
  complement,
  isNil,
  propEq,
  prop,
  map,
  split,
  flatten,
  either,
  isEmpty,
  path,
  pick
} from 'ramda'

/**
 * curryfied version of bindActionCreators
 */
export const action_dispatchor = action_creators => dispatch =>
  bindActionCreators(action_creators, dispatch)

export const branch_loader = testF => branch(testF, renderComponent(LoadingBar))

export const dateUtil = new DateFnsUtils({ locale: frLocale })
dateUtil.addYears = (date, counter) => {
  return dateUtil.setYear(date, dateUtil.getYear(date) + counter)
}

export const analyticsIdentify = ({ email, id }) => {
  const name = email.split('@')[0]

  Sentry.configureScope(scope => {
    scope.setUser({ id })
  })

  window.dataLayer.push({ event: 'identify', identity: { name, email, id } })
}

export const options_from_hashmap = hashmap =>
  Object.keys(hashmap).map(key => ({ value: key, label: hashmap[key] }))

export const simple_options = options => {
  return options.reduce((acc, e) => {
    return [...acc, { label: e, value: e }]
  }, [])
}

export const fccur = (v, precision = 2) => {
  const prec = Math.pow(10, precision - 2)
  const vrect = Math.round(prec * v) / (prec * Math.pow(10, 2))

  const enUs = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: precision
  }).format(vrect)

  return `${enUs.replace(/,/g, ' ').replace(/\./, ',')} €`
}

export const ficur = v => fccur(Math.round(v * 100), 0)

export const plural = (str, x) => {
  if (Array.isArray(str)) {
    return x > 1 ? [str[1]] : str[0]
  }

  return x > 1 ? `${str}s` : str
}

export const absolute_url = url => {
  const {
    location: { host, protocol }
  } = document

  return `${protocol}//${host}${url}`
}

export const date_btoh = (date_iso, format = 'dd/MM/yyyy') =>
  isNaN(dateParse(date_iso)) ? null : dateUtil.format(dateParse(date_iso), format)

export const date_htob = (date_h, format = 'dd/MM/yyyy') => {
  try {
    const parsed = dateUtil.parse(date_h, format)
    return dateUtil.format(parsed, 'yyyy-MM-dd')
  } catch (err) {
    return null
  }
}

export const date_diff_days = (date_iso_left, date_iso_right) => {
  const date_left = dateParse(date_iso_left)
  const date_right = dateParse(date_iso_right)

  return differenceInDays(date_left, date_right)
}

export const date_month_minus = (date_ref, minus) => {
  const date_iso = dateParse(date_ref)
  const date = dateUtil.setMonth(date_iso, dateUtil.getMonth(date_iso) - minus)

  return dateUtil.format(date, 'MMMM yyyy')
}

export const qps_from_search = router_search =>
  router_search ? QueryString.parse(router_search.substring(1)) : {}

export const proxy_image = src => {
  if (!src) return undefined

  // const no_http = src.replace(/(^\w+:|^)\/\//, '')

  // const blacklist_proxy = [/^v\.seloger\.com/, /^upload\.pap\.fr/]

  // if (blacklist_proxy.every(reg => !reg.test(no_http))) {
  //   return `https://images.weserv.nl/?url=${encodeURIComponent(no_http)}`
  // }

  return src
}

export const frontend_search_params_to_backend = values => {
  const params = {
    search_type: values.search_type,
    agglomeration: values.agglomeration,
    dpts: compose(
      map(prop('code')),
      filter(propEq('type', 'dpt'))
    )(values.where),
    geopoints: compose(
      map(pick(['city', 'postalCode', 'latLng', 'radius'])),
      filter(propEq('type', 'geopoint'))
    )(values.where),
    postalCodes: compose(
      map(prop('name')),
      filter(propEq('type', 'zip'))
    )(values.where).concat(
      compose(
        map(path(['properties', 'id'])),
        filter(propEq('selected', true)),
        flatten,
        map(prop('zone')),
        filter(propEq('type', 'multizip'))
      )(values.where)
    ),
    cities: compose(
      map(prop('name')),
      filter(propEq('type', 'city'))
    )(values.where).concat(
      compose(
        map(path(['properties', 'id_city'])),
        filter(propEq('selected', true)),
        flatten,
        map(prop('zone')),
        filter(propEq('type', 'multicity'))
      )(values.where)
    ),
    quartiers: compose(
      map(path(['properties', 'item_name'])),
      filter(propEq('selected', true)),
      flatten,
      map(prop('zone')),
      filter(propEq('type', 'multiquartier'))
    )(values.where),
    stops: compose(
      flatten,
      map(
        compose(
          split(','),
          prop('ids')
        )
      ),
      filter(propEq('type', 'station'))
    )(values.where),
    rentMax: values.rent_max,
    areaMin: values.area_min,
    area_max: values.area_max,
    rent_max_allow_excess: values.rent_max_allow_excess,
    roomMin: values.room_min.substring(1),
    room_max: values.room_max !== 'ns' ? values.room_max.substring(1) : 'ns',
    furnished: values.furnished !== 'ns' ? values.furnished === 'yes' : 'ns',
    buy_type: values.buy_type,
    tenementType: values.tenement_type,
    no_coliving: values.no_coliving === 'no' ? '1' : 'ns',
    rentMin: values.rent_min,
    bedroomMin: values.bedroom_min !== 'ns' ? values.bedroom_min.substring(1) : 'ns',
    floor: values.floor !== 'ns' ? values.floor.substring(1) : 'ns',
    lift: values.lift !== 'ns' ? values.lift.substring(1) : 'ns',
    dpe: values.dpe
      ? values.dpe.length === ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'ns'].length
        ? null
        : values.dpe
      : null,
    land_area_min: values.land_area_min,
    ownerType: values.owner_type !== 'ns' ? values.owner_type : 'ns',
    photos: values.photos !== 'ns' ? values.photos.substring(1) : 'ns',
    match_none: '',
    keyword_match_include: values.keyword_match_include,
    balcony: values.balcony ? +values.balcony : null,
    parking: values.parking ? +values.parking : null,
    garden: values.garden ? +values.garden : null,
    cave: values.cave ? +values.cave : null
  }

  return filter(complement(either(isNil, x => (x !== 0 && isEmpty(x)) || x === 'ns')))(params)
}

export const media_url = (media_token, is_attachment = false) => {
  const query_params = `is_attachment=${is_attachment ? 1 : ''}`
  return `${process.env.REACT_APP_API_ENDPOINT}/media/content/${media_token}?${query_params}`
}

export const url_with_new_qp = (url, qp_key, qp_value) => {
  const url_search = url
    .split('?')
    .slice(1)
    .join('?')
  const url_without_search = url.split('?')[0]
  const qps = QueryString.parse(url_search)
  qps[qp_key] = qp_value

  return `${url_without_search}?${QueryString.stringify(qps)}`
}

export const downloadFile = async (fetchResult, filename) => {
  const data = await fetchResult.blob()
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], { type: data.type || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
    return
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

// XXX easy way be consistent on /alert/ad/:ID which is the legacy endpoint called
export const fetch_ad_id = async (fetch_api, ad_id) => {
  const res = await fetch_api(`ad/${ad_id}`)

  return { ad: { ...res, id: parseInt(res.id, 10), favorite: undefined } }
}
